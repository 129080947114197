<template>
  <!--header-->
    <header class="header">
        <div class="container header__container">
            <a :href="baseUrl"><img src="@/assets/img/logo.png" alt="logo" class="header__logo"></a>
            <div class="side-overlay" :class="[burger ? 'open-side' : '']"></div>
            <Navbar />
            <div class="header__right">
                <div class="header__contact">
                    <span>Beratung:</span>
                    <a href="tel:+493031007700" class="header__contact-link d-none d-lg-block">030/31007700</a>
                    <a href="tel:+493031007700" class="header__contact-link d-lg-none"><i class="fas fa-phone-alt"></i></a>
                    <a href="mailto:info@loewe-pergola.de" class="header__contact-link d-lg-none"><i class="fas fa-envelope"></i></a>
                </div>
                <router-link :to="{ name: 'cart' }" class="cart-link">
                    <i class="fas fa-shopping-cart"></i>
                    <span class="cart-link__badge">{{ itemCount }}</span>
                </router-link>
            </div>
            <button class="menu-burger" @click="openMenu"><span class="menu-burger__wrapper"><span class="menu-burger__line"></span> <span class="menu-burger__line"></span> <span class="menu-burger__line"></span></span></button>
        </div>
    </header>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import Navbar from '~/layouts/partials/navbar'
export default {
  name: 'HeaderPartial',
  components: {
    Navbar
  },
  setup () {
    const store = useStore()
    // computed
    const itemCount = computed(() => {
      const items = store.getters['checkout/items']
      return items.length
    })
    const baseUrl = computed(() => {
      return process.env.VUE_APP_BASE_URL
    })
    const burger = computed(() => {
      return store.getters['menu/statusMenu']
    })
    // methods
    function openMenu () {
      store.commit('menu/SET_STATUS_MENU', true)
    }
    return {
      itemCount,
      burger,
      baseUrl,
      openMenu
    }
  }
}
</script>
